<template lang="html">
    <div>
        <div class="card-wrapper" v-for="ad in ads">
            <div class="card clearfix">
                <a class="card-img" :href="url + '/show/' + ad.id">
                    <img v-if="ad.images[0]" :src="url + '/uploads/card/' + ad.images[0].title" alt="">
                    <img v-else :src="url + '/uploads/placeholder.jpg'" alt="a">
                </a>
                <div title="Добавить в избранное" @click="favorite(ad)" v-bind:class="{active: ad.usersFavorite}" class="favorite"><div v-bind:class="{display: isDisplayed}" class="f-tooltip">Cначала <a href="" class="auth">авторизуйтесь</a></div></div>
                <a class="card-title blacklink" :title="ad.title" :href="url + '/show/' + ad.id">{{ ad.title }}</a>
                <div class="card-separator">
                    <span>хочу взамен:</span>
                </div>
                <div class="card-tags tags">
                    <a class="tag" v-for="tag in ad.tags" :href="url + '/tag/' + tag.name">{{ tag.name }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Masonry from 'masonry-layout';

    export default {
        props: ['ads', 'url'],

        data() {
            return {
                isDisplayed: true,
                isActive: false,
                data: 0,
                activeIndex: null,
            }
        },

        updated: function() {
            var msnry = new Masonry('.card-list', {
                itemSelector: '.card-wrapper',
                columnWidth: '.grid-sizer',
                gutter: '.gutter-sizer',
                percentPosition: true,
                isResizable: false
            });
            this.isActive = true

            $('.favorite').on('click', function(e){
                $(this).toggleClass('active');
            });

            $('.auth').on('click', function(){
                $('.popup-auth').addClass('active');
                $('body').css('overflow','hidden');
                return false
            })
            $('.popup-auth-wrapper').on('click', function(e){
                e.stopPropagation()
            })
        },

        methods: {
            favorite(data) {
                axios.post(ROOTURL + '/favorite/' + data.id).then(response => {
                    this.data = response.data

                    if (response.data == 1) {
                        this.isDisplayed = false
                    }
                });
            },
        }
    }
</script>

<style>
    .display {
        display: none;
    }
    /*.load-wrapper {*/
        /*text-align: center;*/
    /*}*/
    /*.loader {*/
         /*text-align: center;*/
         /*margin: 300px auto;*/
     /*}*/
    /*.no-items {*/
        /*text-align: center;*/
        /*margin-top: 300px;*/
        /*color: #ddd;*/
        /*font-size: 45px;*/
    /*}*/

    /*.f-tooltip {*/
        /*padding: 5px 10px;*/
        /*top: -2px;*/
        /*left: 40px;*/
        /*position: absolute;*/
        /*z-index: 2;*/
        /*background: #fff;*/
        /*border: 1px solid #76A7E1;*/
        /*box-shadow: 0 2px 10px rgba(0,0,0,.1);*/
        /*border-radius: 4px;*/
        /*-moz-border-radius: 4px;*/
        /*-webkit-border-radius: 4px;*/
        /*-o-border-radius: 4px;*/
        /*-khtml-border-radius: 4px;*/
    /*}*/
</style>
