<template lang="html">
    <div class="chat-content">
        <div class="chats active">
            <div class="chats-header">
                <a href="/" class="chat-resp-goback"></a>
                <div class="chats-header-title">Чаты</div>
            </div>
            <div v-for="room in chats">
                <div v-if="user.id != auth_user" v-for="user in room.users">
                    <router-link :to="{ path: '/chats/' + $route.params.param + '/' +room.ad_id+ '/' + user.id }" replace>
                        <div id="message" class="chats-item" :class="[room.ad_id+ '/' + user.id == $route.params.deal + '/' + $route.params.author ? 'active' : '']">
                            <div class="chats-item-thumb">
                                <img v-if="room.ad_image" :src="url + '/uploads/card/' + room.ad_image" alt="">
                                <img v-else :src="url + '/uploads/placeholder.jpg'" alt="a">
                            </div>
                            <div class="chat-item-text">
                                <span class="chats-item-title">{{ room.ad_title }}</span>
                                <span class="chats-item-profile">{{ user.name }}</span>
                                <span v-if="room.messages[0]" class="chats-item-date"> {{ room.messages[0].created_at  | formatChatsDate }} </span>
                                <div class="new-messages-count" v-if="room.not_viewed_messages.length">{{ room.not_viewed_messages.length }}</div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <router-view :url="url" :auth_user="auth_user"></router-view>
        <div class="chat-frame chat-frame-start-wrapper">
            <div class="chat-frame-start">
                <div class="chat-frame-start-title">
                    <svg xmlns="http://www.w3.org/2000/svg" width="191.7" height="185" viewBox="0 0 191.7 185"><defs></defs><rect class="a" width="48" height="48" rx="5" ry="5"/><rect class="b" x="58" y="38" width="42.27" height="10" rx="3" ry="3"/><rect class="b" x="58" y="19" width="48.91" height="10" rx="3" ry="3"/><rect class="b" x="58" width="133.7" height="10" rx="3" ry="3"/><rect class="a" y="137" width="48" height="48" rx="5" ry="5"/><rect class="b" x="58" y="175" width="42.27" height="10" rx="3" ry="3"/><rect class="b" x="58" y="156" width="48.91" height="10" rx="3" ry="3"/><rect class="b" x="58" y="137" width="133.7" height="10" rx="3" ry="3"/><rect class="c" y="69" width="48" height="48" rx="5" ry="5"/><rect class="b" x="58" y="107" width="42.27" height="10" rx="3" ry="3"/><rect class="b" x="58" y="88" width="48.91" height="10" rx="3" ry="3"/><rect class="b" x="58" y="69" width="133.7" height="10" rx="3" ry="3"/></svg>
                    <span>Выберите чат для беседы</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import moment from 'moment'
    moment.locale('ru')

    Vue.filter('formatChatsDate', function(value) {
        if (value) {
            return moment(String(value)).calendar()
        }
    })

    export default {
        props: ['url', 'auth_user'],

        data() {
            return {
                chats: [],
            }
        },

        created() {
            axios.post('/chats/' +  this.$route.params.param ).then(response => {
                this.chats = response.data;
            });
        },

        watch: {
            '$route' () {
                axios.post('/chats/' +  this.$route.params.param ).then(response => {
                    this.chats = response.data;
                })
            }
        },

        updated: function () {
            $('.chats-item').on('click', function () {
                $(this).addClass('active');
                $('.chats-item').each(function(){
                    if (self != this) $(this).removeClass('active');
                });
                if (!$(this).hasClass('active')) {
                    $(this).addClass('active');
                }
            });
            if($('.chats-item').hasClass('active')){
                $('.chats').removeClass('active');
            }
        }
    }
</script>

<style>
    .chat-content a {
        text-decoration: none;
        color: #616161;
    }

    .a,.c{fill:#f5691d;}.a{opacity:0.2;}.b{fill:#f3f3f3;}.c{opacity:0.4;}
</style>