<template lang="html">
<div>
    <h2 class="page-title">Неактивные объявления</h2>

    <div class="card-list my-card-list my-card-list-off">
        <div class="grid-sizer"></div>
        <div class="gutter-sizer" ></div>
        <div class="card-wrapper" v-for="ad in ads">
            <div class="card clearfix">
                <a class="card-img" :href="'/show/' + ad.id">
                    <img v-if="ad.images[0]" :src="'/uploads/card/' + ad.images[0].title" alt="">
                    <img v-else :src="'/uploads/placeholder.jpg'" alt="a">
                </a>
                <a class="card-edit" :href="'/edit/' + ad.id"><span class="orangebutton">Изменить</span></a>
                <a class="card-onoff" :class="[ ad.active ? 'card-off' : 'card-on' ]" href="" @click.prevent="toggleActive(ad)"><span class="orangebutton">{{ ad.active ? 'Отключить' : 'Включить' }}</span></a>
                <a class="card-del" href="" @click.prevent="destroy(ad)"><span class="orangebutton">Удалить</span></a>
                <a class="card-title blacklink" :title="ad.title" :href="'/show/' + ad.id">{{ ad.title }}</a>
                <div class="card-separator">
                    <span>хочу взамен:</span>
                </div>
                <div class="card-tags tags">
                    <a class="tag" v-for="tag in ad.tags" :href="'/tag/' + tag.name">{{ tag.name }}</a>
                </div>
            </div>
        </div>
    </div>
    <div class="load-more" v-if="!finish">
        <a href="" @click.prevent="loadmore()">Загрузить еще</a>
    </div>
</div>
</template>

<script>
import Masonry from 'masonry-layout';

    export default {
        data() {
            return {
                ads: [],
                finish: true,
                offset: 0,
                status: '',
            }
        },

        created() {
            axios.post('/user?status=deactivated').then(response => {
                this.ads = response.data[0];
                this.offset = response.data[1];
                this.finish = false  || this.ads.length == response.data[2];
            });
        },

        mounted: function() {
            $('.card-list').show()
        },

        updated: function() {
            var msnry = new Masonry('.card-list', {
                itemSelector: '.card-wrapper',
                columnWidth: '.grid-sizer',
                gutter: '.gutter-sizer',
                percentPosition: true,
                isResizable: false
            });
        },

        methods: {
            loadmore(data)  {
                axios.post('/user?status=deactivated&offset=' + this.offset, data).then(response => {
                    this.ads = this.ads.concat(response.data[0]);
                    this.finish = !response.data[0].length || this.ads.length == response.data[2];
                    this.offset = this.ads.length;
                });
            },

            toggleActive(ad) {
                axios.post('/toggleactive/' + ad.id);

                var index = this.ads.indexOf(ad)
                this.ads.splice(index, 1);
            },

            destroy(ad) {
                axios.post('/destroy/' + ad.id);

                var index = this.ads.indexOf(ad)
                this.ads.splice(index, 1);
            },
        }
    }
</script>