<template lang="html">
    <div class="chat-frame chat-frame-main">
        <div class="chat-frame-start" v-if="loader">
            <div class="chat-frame-start-title">
                <img src="/img/loader.gif" style="width: 75px; height: 75px;" class="loader"/>
            </div>
        </div>
        <div v-else class="chat-frame-wrapper">
            <div class="chat-frame-head clearfix">
                <div class="fh-left">
                    <div class="chat-resp-menu"></div>
                    <div class="chat-profile">
                        <a class="chat-profile-avatar" :href="url + '/author/' + author.id">
                            <img :src="author.userAvatarURL" alt="">
                        </a>
                        <a class="chat-profile-name blacklink" :href="url + '/author/' + author.id">{{ author.name }}</a>
                    </div>
                </div>
                <div class="fh-right">
                    <div class="chat-menu-link"></div>
                    <div class="chat-menu popup">
                        <div class="popup-tail">
                            <ul>
                                <li><a class="blacklink" href="" @click.prevent="deleteChat">Удалить чат</a></li>
                                <li><a class="blacklink" href="">Заблокировать пользователя</a></li>
                            </ul>
                        </div>
                    </div>
                    <div v-if="ad">
                        <a :href="url + '/show/' + ad_id" class="chat-frame-head-thumb">
                            <img v-if="ad_image" :src="url + '/uploads/card/' + ad_image" alt="">
                            <img v-else :src="url + '/uploads/placeholder.jpg'" alt="">
                        </a>
                        <a :href="url + '/show/' + ad_id" class="chat-frame-head-title blacklink">{{ ad_title }}</a>
                    </div>
                </div>
            </div>
            <div class="chat-frame-dialog">
                <div class="chat-frame-dialog-wrapper">
                    <div class="chat-frame-dialog-scroll">
                        <ul>
                            <li class="clearfix" v-for="message in messages">
                                <div class="chat-message" :class="[ message.user_id == auth_user ? 'chat-message-right' : 'chat-message-left' ]">
                                    <div class="chat-message-text">{{ message.message }}<div v-if="message.attachedImages.length" class="chat-frame-attach-photos">
                                            <a v-for="image in message.attachedImages" class="frame-attach-photo-thumb" :href="'/uploads/attached/' + image.name">
                                                <img :src="'/uploads/attached/' + image.name">
                                            </a>
                                        </div></div>
                                    <div class="chat-message-meta">
                                        <span class="chat-message-time">{{ message.created_at  | formatDate }}</span>
                                        <div v-if="message.user_id == auth_user">
                                            <div v-if="!message.hasOwnProperty('error')" class="chat-message-status" :class="[ message.is_viewed ? 'message-read' : 'message-delivered' ]"></div>
                                            <div v-else class="chat-message-status message-not-delivered"></div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <div v-show="typing" class="chat-message-typing" >
                                 <div id="circleG">
                                    <div id="circleG_1" class="circleG"></div>
                                    <div id="circleG_2" class="circleG"></div>
                                    <div id="circleG_3" class="circleG"></div>
                                 </div>
                                 <span >{{ author.name }} печатает</span>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="chat-message-input">
                <div class="chat-message-input-wrapper">
                    <form action="">
                        <div class="chat-message-input-options">
                            <label class="attach-image">
                                <span title="Прикрепить фото" class="attach-image-button">
                                    <file-upload style="cursor:pointer"
                                        :multiple="true"
                                        :headers="headers"
                                        :maximum="6"
                                        class="upload-file"
                                        post-action="/uploadFiles"
                                        v-model="files"
                                        @input-file="inputFile"
                                        accept="image/*"
                                        ref="upload">
                                    </file-upload>
                                </span>
                            </label>
                            <!--<div title="Прикрепить объявление" class="attach-deal">-->
                            <!--</div>-->
                        </div>
                        <textarea autofocus ref='textarea' placeholder="Введите сообщение..." class="chat-message-input-text" name="" id="input-text" v-model="messageText" v-on:keyup.enter.exact="sendMessage" @keydown="isTyping" @keyup="notTyping"></textarea>
                    </form>
                    <button class="chat-send-message" @click="sendMessage"></button>
                </div>
                <div class="chat-message-attached">
                    <div class="chat-message-attached-photos" v-if="files.length">
                        <div class="attached-photo" v-for="(file, index) in files" :key="file.id">
                            <div class="progress" v-if="file.active || file.progress !== '0.00'">
                                <div :class="{'progress-bar': true, 'progress-bar-striped': true, 'bg-danger': file.error, 'progress-bar-animated': file.active}" role="progressbar" :style="{width: file.progress + '%'}"><span>{{file.progress}}%</span></div>
                            </div>
                            <img v-if="file.thumb" :src="file.thumb">
                            <div class="attached-del-photo" @click.prevent="$refs.upload.remove(file)"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import moment from 'moment'
    import FileUpload from 'vue-upload-component/dist/vue-upload-component.part.js'
    import _ from 'lodash'

    Vue.filter('formatDate', function(value) {
        if (value) {
            return moment(String(value)).format('HH:mm')
        }
    })

    export default {
        props: ['url', 'auth_user'],

        components: {
            FileUpload,
        },

        data() {
            return {
                messages: [],
                author: '',
                ad: '',
                chat_id: 0,
                ad_id: 0,
                ad_title: '',
                ad_image: '',
                exists: 0,
                messageText: '',
                loader: true,
                typing: false,
                isSending: false,

                //upload files
                files: [],
                accept: 'image/png,image/gif,image/jpeg,image/webp',
                extensions: 'gif,jpg,jpeg,png,webp',
                extensions: ['gif', 'jpg', 'jpeg','png', 'webp'],
                extensions: /\.(gif|jpe?g|png|webp)$/i,
                multiple: true,
//                thread: 3,
                name: 'file',
                postAction: '/upload/post',
                putAction: '/upload/put',
                headers: {
                    'X-Csrf-Token': document.head.querySelector('meta[name="csrf-token"]').content,
                },
                data: {
                    '_csrf_token': document.head.querySelector('meta[name="csrf-token"]').content,
                },
            }
        },

        methods: {
            sendMessage() {
                var text = this.messageText
                var images = []
                var name = []

                if (this.files.length) {
                    var files = this.files

                    files.forEach(function(file, i, files) {
                        images.push(file.response)
                        name.push({ name: file.response })
                    })

                    if (!this.messageText) {
                        text = ' '
                    }
                }

                const randomId = Math.round(Math.random() * 100000);

                if (this.$refs.upload.uploaded && !this.isSending && text) {
                    this.isSending = true;

                    this.messages.push(
                        {
                            "id": randomId,
                            "message": text,
                            "user_id": this.auth_user,
                            "chat_id": this.chat_id,
                            "is_viewed": 0,
                            "created_at": moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                            "attachedImages": name
                        });

                    this.files.length = 0

                    axios.post('/messages', {
                        message: text,
                        chat_id: this.chat_id,
                        images: images
                    }).then(response => {
                        this.messages = this.messages.map(o => {
                            if (o.id === randomId) {
                                return response.data;
                            }
                            return o;
                        });
                        this.isSending = false;
                        images = null
                    }).catch(() => {
                        this.isSending = false;
                        this.messages = this.messages.map(o => {
                            if (o.id === randomId) {
                                o.error = true;
                            }
                            return o;
                        });
                    })
                    this.messageText = ''
                }
            },

            deleteChat() {
                axios.post('/destroy_chat/' + this.chat_id).then(response => {
                    window.location.href = ROOTURL + "/chats/" + this.$route.params.param;
                })
            },

            isTyping: _.debounce(function () {
                let _this = this;
                Echo.private('chatroom.' + _this.chat_id).whisper('typing', {
                    typing: true
                });
            }, 100),

            notTyping: _.debounce(function () {
                let _this = this;
                Echo.private('chatroom.' + _this.chat_id).whisper('typing', {
                    typing: false
                });
            }, 4000),

            //upload files
            inputFile(newFile, oldFile, prevent) {
                this.$refs.upload.active = true

                if (newFile && oldFile) {
                    if (newFile.active && !oldFile.active) {
                        // beforeSend
                        // min size
                        if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
                            this.$refs.upload.update(newFile, {error: 'size'})
                        }
                    }
                }

                if (!newFile && oldFile) {
                    // remove
                    axios.post(ROOTURL + '/remove/' + oldFile.response);
                }

                if (newFile && (!oldFile || newFile.file !== oldFile.file)) {

                    // Create a blob field
                    newFile.blob = ''
                    let URL = window.URL || window.webkitURL
                    if (URL && URL.createObjectURL) {
                        newFile.blob = URL.createObjectURL(newFile.file)
                    }

                    // Thumbnails
                    newFile.thumb = ''
                    if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
                        newFile.thumb = newFile.blob
                    }
                }
            },
        },

        created() {
            axios.post('/chats/' +  this.$route.params.param +
                '/' + this.$route.params.deal +
                '/' + this.$route.params.author)
            .then(response => {
                this.messages = response.data[0]
                this.author = response.data[1]
                this.ad = response.data[2]
                this.chat_id = response.data[3].id
                this.ad_id = response.data[3].ad_id
                this.ad_title = response.data[3].ad_title
                this.ad_image = response.data[3].ad_image
                this.exists = response.data[3].exists
                this.loader = false

                Echo.private('chatroom.' + this.chat_id)
                    .listen('MessagePosted', (e) => {
                        this.messages.push({
                            message: e.message.message,
                            attachedImages: e.message.attachedImages,
                            user: e.user,
                            created_at: e.message.created_at,
                        })

                    axios.post('/chats/' +  this.$route.params.param +
                        '/' + this.$route.params.deal +
                        '/' + this.$route.params.author)
                })

                Echo.private('chatroom.' + this.chat_id)
                    .listenForWhisper('typing', (e) => {
                        this.typing = e.typing;
                    });
            })
        },

        watch: {
            '$route' () {
                Echo.leave('chatroom.' + this.chat_id)
                this.loader = true

                axios.post('/chats/' +  this.$route.params.param
                        + '/' + this.$route.params.deal
                        + '/' + this.$route.params.author)
                    .then(response => {
                    this.messages = response.data[0]
                    this.author = response.data[1]
                    this.ad = response.data[2]
                    this.chat_id = response.data[3].id
                    this.ad_id = response.data[3].ad_id
                    this.ad_title = response.data[3].ad_title
                    this.ad_image = response.data[3].ad_image
                    this.exists = response.data[3].exists
                    this.loader = false

                    Echo.private('chatroom.' + this.chat_id)
                    .listen('MessagePosted', (e) => {
                        this.messages.push({
                            message: e.message.message,
                            attachedImages: e.message.attachedImages,
                            user: e.user,
                            created_at: e.message.created_at,
                        })

                        axios.get('/chats/' +  this.$route.params.param +
                            '/' + this.$route.params.deal +
                            '/' + this.$route.params.author)
                    })

                    Echo.private('chatroom.' + this.chat_id)
                        .listenForWhisper('typing', (e) => {
                            this.typing = e.typing;
                        })
                })
            }
        },

        updated: function() {
            scrollBottomChat()
            ChatHeight()

            if (this.$refs.textarea) {
                this.$refs.textarea.focus()
            }

            var timer;

            $('.chat-menu-link, .chat-menu.popup').on("mouseover", function() {
                clearTimeout(timer);
                openPopup();
            }).on("mouseleave", function() {
                timer = setTimeout(
                    closePopup
                    , 200);
            });
            function openPopup(){
                $('.chat-menu.popup').addClass('active');
            }
            function closePopup() {
                $('.chat-menu.popup').removeClass('active');
            }

            $('.chat-message-input-text').keypress(function(e){
                if(e.which == 13 && !e.shiftKey) {
                    e.preventDefault();
                    return false;
                }
            });

            autosize(document.querySelectorAll('.chat-message-input-text'));
        }
    }
</script>
