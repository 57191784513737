<template lang="html">
    <div>
        <div class="image-uploaded" v-for="image in images" style="display: inline-block">
            <div class="attached-del-photo" @click="remove(image)"></div>
            <img :src="'/uploads/card/' + image.title" width="100" height="70" />
        </div>
    </div>
</template>

<script>

    export default {
        props: ['images'],

        methods: {
            remove: function(image) {
                axios.post(ROOTURL + '/delete/' + image.title);

                var index = this.images.indexOf(image)
                this.images.splice(index, 1);
            },
        }
    }
</script>

<style>
    .image-upload .upload-file {
        margin-left: -50px;
    }
</style>