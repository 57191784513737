<template lang="html">
    <div>
        <div v-for="(file, index) in files" :key="file.id">
            <div class="image-uploaded" href="#">
                <div class="attached-del-photo" @click.prevent="$refs.upload.remove(file)"></div>
                <div class="progress" v-if="file.active || file.progress !== '0.00'">
                    <div :class="{'progress-bar': true, 'progress-bar-striped': true,
                     'bg-danger': file.error, 'progress-bar-animated': file.active}"
                         role="progressbar" :style="{width: file.progress + '%'}">
                        <span v-if="file.error">Ошибка</span><span v-else>{{file.progress}}%</span>
                    </div>
                </div>
                <img v-if="file.thumb" :src="file.thumb" width="100" height="70" />
                <span v-else>No Image</span>
            </div>
        </div>
        <input v-if="!file.error" v-for="file in files" type="hidden" name="images[]" :value="file.response">
        <div v-show="files.length + images.length < 6" class="image-upload image-uploaded">
            <file-upload style="cursor:pointer"
                :multiple="true"
                :headers="headers"
                :maximum="6"
                class="upload-file"
                post-action="/uploadImages"
                v-model="files"
                @input-file="inputFile"
                accept="image/*"
                ref="upload">
            </file-upload>
            <span>Добавить фото</span>
        </div>
    </div>
</template>

<script>
    import FileUpload from 'vue-upload-component/dist/vue-upload-component.part.js'
    export default {
        props: ['images'],

        components: {
            FileUpload,
        },
        data() {
            return {
                files: [],
                accept: 'image/png,image/gif,image/jpeg,image/webp',
                extensions: 'gif,jpg,jpeg,png,webp',
                extensions: ['gif', 'jpg', 'jpeg','png', 'webp'],
                extensions: /\.(gif|jpe?g|png|webp)$/i,
                multiple: true,
//                thread: 3,
                name: 'file',
                postAction: '/upload/post',
                putAction: '/upload/put',
                headers: {
                    'X-Csrf-Token': document.head.querySelector('meta[name="csrf-token"]').content,
                },
                data: {
                    '_csrf_token': document.head.querySelector('meta[name="csrf-token"]').content,
                },
            }
        },

        methods: {
            inputFile(newFile, oldFile, prevent) {
                this.$refs.upload.active = true

                if (newFile && oldFile) {
                    if (newFile.active && !oldFile.active) {
                        // beforeSend
                        // min size
                        if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
                            this.$refs.upload.update(newFile, {error: 'size'})
                        }
                    }
                }

                if (!newFile && oldFile) {
                    // remove
                    axios.post(ROOTURL + '/delete/' + oldFile.response);
                }

                if (newFile && (!oldFile || newFile.file !== oldFile.file)) {

                    // Create a blob field
                    newFile.blob = ''
                    let URL = window.URL || window.webkitURL
                    if (URL && URL.createObjectURL) {
                        newFile.blob = URL.createObjectURL(newFile.file)
                    }

                    // Thumbnails
                    newFile.thumb = ''
                    if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
                        newFile.thumb = newFile.blob
                    }
                }
            },
        }
    }
</script>

<style>
    .image-upload .upload-file {
        margin-left: -50px;
        z-index: 2;
    }

    #file {
        top: -20px;
        width: 100px;
        height: 85px;
        position: absolute;
        left: 50px;
        cursor: pointer;
        z-index: 10;
    }

    .bg-danger {
        background-color: red;
    }
</style>