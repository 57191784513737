<template lang="html">
    <div v-if="!finish">
        <div v-if="loading" class="load-more">
            <img src="/img/loader.gif" style="width: 75px; height: 75px;"/>
        </div>
        <div v-else class="load-more">
            <a href="" @click.prevent="loadmore()">Загрузить еще</a>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['ads',
            'search',
            'option',
            'finish',
            'offset',
            'category',
            'tag',
            'param_link',
            'loading'],


        methods: {
            loadmore: function() {
                this.loading = true
                this.$emit('loadedads', {
                    offset: this.offset,
                    search: this.search,
                    option: this.option,
                    category: this.category,
                    tag: this.tag,
                    param_link: this.param_link,
                });
            }
        }
    }
</script>