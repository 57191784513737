<template lang="html">
<div>
    <h2 class="page-title">Избранные объявления</h2>

    <div class="card-list my-card-list-favorite">
        <div class="grid-sizer"></div>
        <div class="gutter-sizer" ></div>
        <div class="card-wrapper" v-for="ad in ads">
            <div class="card clearfix">
                <a class="card-img" :href="'/show/' + ad.id">
                    <img v-if="ad.images[0]" :src="'/uploads/card/' + ad.images[0].title" alt="">
                    <img v-else :src="'/uploads/placeholder.jpg'" alt="a">
                </a>
                <div title="Добавить в избранное" @click="favorite(ad)" class="favorite active"></div>
                <a class="card-title blacklink" :title="ad.title" :href="'/show/' + ad.id">{{ ad.title }}</a>
                <div class="card-separator">
                    <span>хочу взамен:</span>
                </div>
                <div class="card-tags tags">
                    <a class="tag" v-for="tag in ad.tags" :href="'/tag/' + tag.name">{{ tag.name }}</a>
                </div>
            </div>
        </div>
    </div>
    <div class="load-more" v-if="!finish">
        <a href="" @click.prevent="loadmore()">Загрузить еще</a>
    </div>
</div>
</template>

<script>
import Masonry from 'masonry-layout';

    export default {
        data() {
            return {
                ads: [],
                finish: true,
                offset: 0,
                status: '',
            }
        },

        created() {
            axios.post('/user?status=favorite').then(response => {
                this.ads = response.data[0];
                this.offset = response.data[1];
                this.finish = false  || this.ads.length == response.data[2];
            });
        },

        mounted: function() {
            $('.card-list').show()
        },

        updated: function() {
            var msnry = new Masonry('.card-list', {
                itemSelector: '.card-wrapper',
                columnWidth: '.grid-sizer',
                gutter: '.gutter-sizer',
                percentPosition: true,
                isResizable: false
            });

            $('.favorite').on('click', function(e){
                $(this).toggleClass('active');
            });
        },

        methods: {
            loadmore(data)  {
                axios.post('/user?status=favorite&offset=' + this.offset, data).then(response => {
                    this.ads = this.ads.concat(response.data[0]);
                    this.finish = !response.data[0].length || this.ads.length == response.data[2];
                    this.offset = this.ads.length;
                });

                $('.favorite').on('click', function(e){
                    $(this).toggleClass('active');
                });
            },

            favorite(data) {
                axios.post(ROOTURL + '/favorite/' + data.id).then(response => {
                    this.data = response.data
                });
            },
        }
    }
</script>