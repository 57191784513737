<template lang="html">
    <div>
        <div class="form-item">
            <input required placeholder="Имя" class="input-text" v-model="name" name="name" type="text">
            <span class="requared">*</span>
            <span class="help-block">
                <strong>{{ errors.firstname }}</strong>
            </span>
        </div>
        <div class="form-item">
            <input required placeholder="E-mail" class="input-text" v-model="email" name="email" type="text">
            <span class="requared">*</span>
            <span class="help-block">
                <strong>{{ errors.mail }}</strong>
            </span>
        </div>
        <div class="form-item">
            <input required placeholder="Пароль" class="input-text" v-model="password" name="password" type="password">
            <span class="requared">*</span>
            <span class="help-block">
                <strong>{{ errors.pass }}</strong>
            </span>
        </div>
        <button class="orangebutton" @click.prevent="register">Зарегистироваться</button>
    </div>
</template>

<script>
    export default {
        props: ['errors'],

        data() {
            return {
                name: '',
                email: '',
                password: ''
            }
        },

        methods: {
            register: function() {
                this.$emit('register', {
                    name: this.name,
                    email: this.email,
                    password: this.password,
                });
            }
        },
    }
</script>