
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

import Vue from 'vue'
import VueRouter from 'vue-router'
import Masonry from 'masonry-layout'
import AdsComposer from './components/AdsComposer'
import LoadAds from './components/LoadAds'
import UploadImages from './components/UploadImages'
import DeleteImages from './components/DeleteImages'
import Login from './components/Login'
import Register from './components/Register'
import Chats from './components/Chats'
import AuthorAds from './components/AuthorAds'
import ChatComposer from './components/ChatComposer'
import ActiveAds from './components/ActiveAds'
import DeactivatedAds from './components/DeactivatedAds'
import FavoriteAds from './components/FavoriteAds'

Vue.use(VueRouter)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.component('ads-composer', AdsComposer);
Vue.component('load-ads', LoadAds);
Vue.component('upload-images', UploadImages);
Vue.component('delete-images', DeleteImages);
Vue.component('login', Login);
Vue.component('register', Register);
Vue.component('chats', Chats);
Vue.component('author-ads', AuthorAds)
Vue.component('chat-composer', ChatComposer)
Vue.component('active-ads', ActiveAds)
Vue.component('deactivated-ads', DeactivatedAds)
Vue.component('favorite-ads', FavoriteAds)

// const ActiveAds = require('./components/ActiveAds.vue')
// const DeactivatedAds = require('./components/DeactivatedAds.vue')
// const FavoriteAds = require('./components/FavoriteAds.vue')
// const AuthorAds = require('./components/AuthorAds.vue')
// const Chats = require('./components/Chats.vue')
// const ChatComposer = require('./components/ChatComposer.vue')

if ($('#root').length > 0) {
    const root = new Vue({
        el: '#root',

        methods: {
            loadAds(ad) {
                axios.post('/', ad).then(response => {
                    this.ads = this.ads.concat(response.data[0]);
                    this.offset = Number(this.ads.length) + Number(offset);
                    this.finish = !response.data.length || Number(this.ads.length) + Number(offset) == response.data[1];
                    this.loading = false
                });

                $('.favorite').on('click', function (e) {
                    $(this).toggleClass('active');
                });
            },

            favorite(data) {
                axios.post(ROOTURL + '/favorite/' + data).then(response => {
                    this.data = response.data
                });
            },

            // Methods for searching
            fetch(searchkey) {
                searchkey = this.searchmethod ? 'ad' : 'tag';
                axios.get('/search', { params: { [searchkey]: this.keywords } })
                    .then(response => this.results = response.data);
            },

            highlight(text) {
                if (text) {
                    return text.replace(new RegExp(this.keywords, 'gi'), '<span id="highlighted">$&</span>');
                }
                this.results = []
            },

            assign_option() {
                this.searchmethod = !this.searchmethod
            },
        },

        watch: {
            keywords() {
                this.fetch(this.searchkey);
            }
        },

        data: {
            ads: [],
            option: 0,
            offset: 0,
            category: 0,
            tag: '',
            param_link: '',
            finish: false,
            data: 0,
            searchmethod: true,

            keywords: null,
            results: [],
            searchkey: '',
        },

        created() {
            this.offset = offset;
            this.finish = !ads.length || ads.length == all_ads;
            this.option = option;
            this.search = search;
            this.category = category;
            this.tag = tag;
            this.param_link = param_link
        },

        mounted: function() {
            $('.card-list, .popup-auth').show()
            $('.load-wrapper').hide()
            var msnry = new Masonry('.card-list', {
                itemSelector: '.card-wrapper',
                columnWidth: '.grid-sizer',
                gutter: '.gutter-sizer',
                percentPosition: true,
                isResizable: false
            });
        }
    });
}

if ($('#edit').length > 0) {
    const edit = new Vue({
        el: '#edit',

        data: {
            images: [],

            keywords: null,
            results: [],
            searchkey: 'location',
        },

        created() {
            if (ad_id) {
                axios.post('/edit/' + ad_id).then(response => {
                    this.images = response.data
                });
            }
        },

        methods: {
            // searching location from vk api
            fetch(searchkey) {
                axios.get('/location', { params: { [searchkey]: this.keywords } }).then(response => {
                    this.results = response.data['response']['items']
                });
            },

            highlight(text) {
                if (text) {
                    return text.replace(new RegExp(this.keywords, 'gi'), '<span id="highlighted">$&</span>');
                }
                this.results = []
            },
        },

        watch: {
            keywords() {
                this.fetch(this.searchkey);
            }
        },
    });
}

if ($('#user').length > 0) {
    const router = new VueRouter({
        mode: 'history',
        routes: [
            { path: '/user/status=active', component: ActiveAds },
            { path: '/user/status=deactivated', component: DeactivatedAds },
            { path: '/user/status=favorite', component: FavoriteAds },
            { path: '/author/' + author_id, component: AuthorAds },
        ]
    })

    const user = new Vue({
        router,
        el: '#user',

        mounted: function () {
            $('.popup-auth').show()
        }
    });
}

if ($('#chat-router').length > 0) {
    const router = new VueRouter({
        mode: 'history',
        routes: [
            { path: '/chats/:param', component: Chats,
                children: [
                    {
                        path: ':deal/:author',
                        component: ChatComposer
                    },
                ]
            },
            // { path: '/chats/:param/:deal/:author', component: ChatComposer },
        ]
    });

    const chat = new Vue({
        router,
        el: '#chat-router',
    });
}

const auth = new Vue({
    el: '#auth',

    data: {
        errors: {
            mail: '',
            firstname: '',
            pass: '',
            confirmed: ''
        }
    },

    methods: {
        auth(data) {
            axios.post('/login', data).then(response => {
                location.reload()
            }).catch(function (error) {
                this.errors.mail = error.response.data.email || '';
                this.errors.pass = error.response.data.password[0] || '';
                this.errors.confirmed = error.response.data.confirmed[0] || '';
            }.bind(this));
        },

        register(data) {
            axios.post('/register', data).then(response => {
                window.location.href = ROOTURL + "/confirmation?email=" + response.data.email;
            }).catch(function (error) {
                this.errors.mail = error.response.data.email[0] || '';
                this.errors.pass = error.response.data.password[0] || '';
                this.errors.firstname = error.response.data.name[0] || '';
            }.bind(this));
        },
    },

    mounted: function () {
        $('.popup-auth').show()
    }
})
