<template lang="html">
    <div>
        <div class="form-item">
            <input required placeholder="E-mail" class="input-text" v-model="email" name="email" type="text" >
            <span class="help-block">
                <strong>{{ errors.mail }}{{ errors.confirmed }}</strong>
            </span>
        </div>
        <div class="form-item auth_form_pass">
            <input placeholder="Пароль" class="input-text" v-model="password" name="password" type="password" required>
            <span class="help-block">
                <strong>{{ errors.pass }}</strong>
            </span>
            <a href="/password/reset" class="orangelink forgot_link">Забыли?</a>
        </div>
        <button class="orangebutton" @click.prevent="login">Войти</button>
    </div>
</template>

<script>
    export default {
        props: ['errors'],

        data() {
            return {
                email: '',
                password: ''
            }
        },

        methods: {
            login: function() {
                this.$emit('login', {
                    email: this.email,
                    password: this.password,
                });
            }
        },
    }
</script>